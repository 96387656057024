<template>
  <div
      id="app"
      data-app
      @change="fullscreenChange"
      :class="{faded: isFaded, 'is_mobile': isUAMobile, vhfix: vhfix}"
  >
    <top-menu />
    <!--    <HelpOverlay/>-->
    <transition :name="transitionName" :mode="transitionMode">
      <jungle v-if="$route.meta.jungle">
        <transition name="fade" mode="out-in">
          <c-loading v-if="initializing"/>
          <router-view v-else/>
        </transition>
      </jungle>

      <router-view v-else/>
    </transition>

    <notification/>

    <help-overlay/>

    <reward-overlay/>

    <c-debug-panel/>

    <c-bug-report-button id="debug-button-app" v-if="!$route.meta.jungle"/>

    <c-bug-report-modal />
  </div>
</template>

<script>
import fullscreen from "vue-fullscreen";
import Vue from "vue";
import TopMenu from "./components/TopMenu/TopMenu";
import Notification from "@/components/Notification";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import CDebugPanel from "@/components/Debug/DebugPanel.vue";
import Jungle from "@/components/Base/Jungle";
import CLoading from "@/components/Loading";
import HelpOverlay from "@/components/Base/HelpOverlay";
import RewardOverlay from "@/components/Base/RewardOverlay";
import CBugReportButton from "@/components/Debug/BugReportButton.vue";
import CBugReportModal from "@/components/Debug/BugReportModal.vue";

Vue.use(fullscreen);

export default {
  components: {
    CBugReportModal,
    CBugReportButton, RewardOverlay, HelpOverlay, CLoading, Jungle, CDebugPanel, Notification, TopMenu},
  data: () => ({
    fullscreen: false,
    force: true,
    menuKey: 0,
    backgroundAudio: null,
  }),
  methods: {
    ...mapActions({
      fetchProfile: "user/fetchProfile",
      fetchBadges: "user/fetchBadges",
      playBackgroundMusic: "audio/playBackgroundMusic",
      playEntrySound: "audio/playEntrySound",
      proxifyConsoleError: "debug/proxifyConsoleError",
    }),
    ...mapMutations({
      setWorld: "world/SET_WORLD",
      appendError: "debug/APPEND_ERROR"
    }),
    toggle() {
      this.$refs["fullscreen"].toggle(this.force);
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    // initializeApp() {
    //   if (!this.isLoggedIn && this.$route.meta.auth) {
    //     this.fetchProfile() this.fetchBadges()
    //         .then(() => {
    //         })
    //         .catch((error) => {
    //           this.$store.dispatch("user/logout");
    //           throw error;
    //         });
    //   }
    // },
  },
  mounted() {
    this.playBackgroundMusic(this.$route.path)
    this.playEntrySound(this.$route.path)
    if (this.$route.meta.jungle ?? false) {
      document.documentElement.className = 'jungle';
    }
    window.addEventListener('resize', () => {
      this.updateMobile(true);
    })
    this.proxifyConsoleError()
  },
  computed: {
    ...mapState({
      helpOverlay: "helpOverlay"
    }),
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      initializing: "isInitializing",
      isFaded: "isFaded",
    }),
    transitionName() {
      return this.$route.meta.jungle ? 'scale-fade' : 'fade'
    },
    transitionMode() {
      return this.$route.meta.jungle ? null : 'out-in'
    },
  },
  watch: {
    $route(newValue) {
      if (newValue.path === "/") {
        this.setWorld(null)
      }
      this.playBackgroundMusic(this.$route.path)
      this.playEntrySound(this.$route.path)
      if (this.$route.meta.jungle ?? false) {
        document.documentElement.className = 'jungle';
      } else {
        document.documentElement.className = "";
      }
    },
  },
};
</script>

<style lang="scss">
html {
  overflow: hidden !important;
  transition: color 0.3s;

  //::-webkit-scrollbar-thumb:hover {
  //  background-color: rgba(0, 0, 0, 0.58);
  //}
  //
  //::-webkit-scrollbar {
  //  background-color: rgba(0, 0, 0, 0);
  //  width: 10px;
  //  height: 10px;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  background-color: #146b4b;
  //}
  //
  //::-webkit-scrollbar-corner {
  //  background-color: rgba(0, 0, 0, 0);
  //}


  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #5CB08C;
    width: 20px;
    border-radius: 15px;
    max-height: 65%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #146B4B;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

body {
  margin: 0;
  background-color: #016d47;
}

.fullscreen {
  // background: url("./assets/achtergrond.png") no-repeat !important;
  // background-size: cover !important;
}

#app {
  position: relative;
  background-color: #016d47;
  // background: url("./assets/logo_groot.png") no-repeat;
  background-size: inherit;
  background-position: center;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: auto;
  height: 100vh;

  &:before {
    content: "";
    position: fixed;
    /* top: 80px; */
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: background-color .5s;
    z-index: 95;
  }

  &.faded {
    &:before {
      background: rgba(0, 0, 0, 0.4);
      pointer-events: all;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
