<template>
  <div class="profile-flexbox__column">
    <div class="text-input__wrapper">
      <span class="form-label">Wat is je voornaam?</span>
      <v-text-field
          v-model="value.first_name"
          type="text"
          :rules="[(v) => !!v || 'Voer je voornaam in']"
          required
          background-color="#fff"
          outlined
      ></v-text-field>
    </div>


    <div class="text-input__wrapper">
      <span class="form-label">Wat is je achternaam?</span>
      <v-text-field
          v-model="value.last_name"
          type="text"
          :rules="[(v) => !!v || 'Voer je achternaam in']"
          required
          background-color="#fff"
          outlined
      ></v-text-field>
    </div>


    <div class="text-input__wrapper">
      <span class="form-label">Kies hier je schermnaam</span>
      <v-text-field
          type="text"
          v-model="value.screen_name"
          :rules="[(v) => !!v || 'Voer je schermnaam in']"
          required
          background-color="#fff"
          outlined
      ></v-text-field>
    </div>


    <div class="text-input__wrapper"><span class="form-label">Wanneer ben je geboren?</span>
      <v-dialog
          ref="datepickerdialog"
          v-model="dialog"
          width="290"
          :return-value.sync="birthDate"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="displayDateOfBirth"
              placeholder="Jouw geboortedatum"
              required
              readonly
              :rules="datePickerRules"
              v-bind="attrs"
              v-on="on"
              background-color="#fff"
              outlined
          >
          </v-text-field>
        </template>
        <v-date-picker
            v-model="datepickerDateOfBirth"
            scrollable
            locale="nl-nl"
            color="#016D47"

            light
        />
      </v-dialog>
    </div>


    <div class="text-input__wrapper"><span class="form-label">In welke groep zit je?</span>
      <v-select
          :items="groups"
          v-model="value.group_id"
          :rules="[(v) => !!v || 'Voer in in welke groep je zit']"
          required
          background-color="#fff"
          outlined
          hide-details
      />
    </div>


  </div>
</template>

<script>
import Moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: 'ProfileForm',
  props: ['value'],
  data: () => ({
    birthDate: null,
    dialog: false,
    /** @var {Student} */
  }),
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      dateOfBirthMoment: "user/getDateOfBirthMoment",
      groups: "user/getLevels",
      buddyId: "user/getBuddyId",
    }),
    now() {
      return Moment().format("DD-MM-YYYY");
    },
    displayDateOfBirth() {
      return Moment.unix(this.value.date_of_birth).format("DD-MM-YYYY");
    },
    datePickerRules() {
      if (!this.datePickerOpen) {
        return [(v) => !!v || "Voer je geboortedag in"];
      }
      return [];
    },
    datepickerDateOfBirth: {
      set(value) {
        this.dialog = false;
        this.value.date_of_birth = Moment(value).unix()
      },
      get() {
        return Moment.unix(this.value.date_of_birth).format("YYYY-MM-DD")
      }
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return ''
      value = Moment(value).format("DD-MM-YYYY")
      return value
    }
  }
}
</script>

<style scoped lang="scss">
.form-label {
  color: #016d47;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 100%;
  text-align: left;
}

.text-input__wrapper {
  display: flex;
  flex-direction: column;
}

:deep(.v-input) {
  .v-input__icon {
    height: 3.5rem;
  }
}

.v-input ::v-deep {
  .v-messages__message {
    color: red;
  }

  .v-select__selections {
    line-height: normal;
  }

  .v-select__selection, input {
    font-weight: bold;
    font-size: 1em !important;
    color: #016d47 !important;
  }
}

.v-input__slot, .v-text-field {
  min-height: 4vh !important;
}
</style>

<style lang="scss">
.text-input__wrapper  {
  .v-text-field__details {
    padding: 0 !important;
    margin: 0 !important;
    max-height: 0;
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
  }
  .v-input__slot {
    margin: 0;
    min-height: 5vh !important;
  }
  .v-input  {
    .v-input__append-inner {
      margin: 4px;
    }
    .v-input__control {
      position: relative;
    }
  }
}
</style>
