import { Model } from "@/models/Model";
import DetailedResult from "@/models/exercises/DetailedResult";
import ExerciseData from "@/models/exercises/ExerciseData";

export default class ExerciseStudent extends Model {
  constructor({
    student_id,
    exercise_id,
    difficulty,
    current_word,
    started_at,
    completed_at,
    status,
    is_complete,
    words,
    exercise_data,
    expected_input,
    detailed_results,
    documents,
    percentage_score,
    is_retry,
  }) {
    super();
    this.student_id = student_id;
    this.exercise_id = exercise_id;
    this.difficulty = difficulty;
    this.current_word = current_word;
    this.started_at = started_at;
    this.completed_at = completed_at;
    this.status = status;
    this.is_complete = is_complete;
    this.words = words;
    this.documents = documents;
    this.exercise_data = exercise_data ? new ExerciseData(exercise_data) : null;
    this.expected_input = expected_input;
    this.percentage_score = percentage_score;
    this.is_retry = is_retry;
    /** @var {DetailedResult[]} */
    this.detailed_results = detailed_results.map(
      (result) => new DetailedResult(result)
    );
  }

  isPassed() {
    return this.percentage_score && this.percentage_score >= 80;
  }

  isForcedRepeat() {
    return this.is_retry;
  }
}
