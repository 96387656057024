import Vue from "vue";
import Vuex from "vuex";
import notification from "@/store/modules/notification";
import user from "@/store/modules/user";
import auth from "@/store/modules/auth";
import exercise from "@/store/modules/exercise";
import debug from "@/store/modules/debug";
import router from "@/store/modules/router";
import audio from "@/store/modules/audio";
import lessonPage from "@/store/modules/lesson-page";
import template from "@/store/modules/template";
import timer from "@/store/modules/timer";
import world from "@/store/modules/world";
import activity from "@/store/modules/activity";
import helpOverlay from "@/store/modules/helpOverlay";
import pusher from "@/store/modules/pusher";
import overlay from "@/store/modules/overlay";
import Cookies from "js-cookie";

Vue.use(Vuex);

// TODO make generic overlay module, combine HelpOverlay and RewardOverlay

export default new Vuex.Store({
  state: {
    initializing: false,
    refreshingToken: false,
    debugPanelVisibility: parseInt(Cookies.get("debug_panel")),
    isDev:
      process.env.NODE_ENV === "development" ||
      window.location.hostname === "localhost" ||
      window.location.hostname === "a.mijn.spellit.nl" ||
      window.location.hostname === "mijn.spellit.erikict.nl",
    devPanel: true,
  },
  actions: {
    startInitialization({ commit }) {
      commit("START_INITIALIZATION");
      console.log("START_INITIALIZATION");
    },
    stopInitialization({ commit }) {
      commit("STOP_INITIALIZATION");
      console.log("STOP_INITIALIZATION");
    },
    toggleDebugPanel({ commit }) {
      const newValue = parseInt(Cookies.get("debug_panel")) ? 0 : 1;
      Cookies.set("debug_panel", newValue);
      commit("SET_DEBUG_PANEL_VISIBILITY", newValue);
      console.log("Debug panel " + (newValue ? "activated" : "deactivated"));
    },
  },
  getters: {
    isInitializing: (state) => {
      return state.initializing;
    },
    isFaded: (state, getters, rootState, rootGetters) => {
      return (
        rootState.helpOverlay.isVisible || rootGetters["overlay/isAnyVisible"]
      );
    },
  },
  mutations: {
    START_INITIALIZATION(state) {
      state.initializing = true;
    },
    STOP_INITIALIZATION(state) {
      state.initializing = false;
    },
    TOGGLE_DEV_PANEL(state) {
      state.devPanel = !state.devPanel;
    },
    SET_DEBUG_PANEL_VISIBILITY(state, payload) {
      state.debugPanelVisibility = payload;
    },
  },
  modules: {
    notification,
    exercise,
    user,
    auth,
    debug,
    audio,
    router,
    lessonPage,
    template,
    timer,
    world,
    activity,
    helpOverlay,
    overlay,
    pusher,
  },
});
