<template>
  <div
      class="jungle-container"
      :style="{backgroundImage}"
  >
    <div class="jungle-content-wrapper jungle-aspect-ratio">
      <go-back :expect-event="!!customBacklink" @click="$router.push(customBacklink)"/>

      <div class="page-title">
        <transition name="fade" mode="out-in" :key="jungleTitle">
          <h1 style="padding: 0 25px;" class="dots" :key="jungleTitle">{{ jungleTitle }}</h1>
        </transition>
      </div>

      <transition name="fade" mode="out-in">
        <div class="jungle-aspect-ratio" :key="$route.name">
          <slot/>

          <c-bug-report-button id="debug-button-jungle"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import CBugReportButton from "@/components/Debug/BugReportButton.vue";

export default {
  name: "Jungle",
  components: {CBugReportButton, GoBack},
  props: {
    title: {
      default: null,
      type: String
    }
  },
  beforeMount() {
    this.backgroundImage = 'url("' + require("@/assets/" + (this.$route.meta.jungle?.background ?? "background.jpg")) + '")'
  },
  data: () => ({
    metaTitle: null,
    backgroundImage: null,
  }),
  computed: {
    customBacklink() {
      if (typeof this.$route.meta.jungle?.backlink === 'function') {
        return this.$route.meta.jungle?.backlink();
      }

      return this.$route.meta.jungle?.backlink;
    },
    jungleTitle() {
      if (typeof this.$route.meta.jungle?.title === 'function') {
        return this.$route.meta.jungle?.title();
      }

      return this.$route.meta.jungle?.title;
    },
  },
};
</script>

<style scoped lang="scss">
.jungle-container {
  background: url("../../assets/background.jpg");
  background-size: cover;
  height: calc(100vh - 80px);
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: overlay;
  padding: 120px 40px 0 40px;

  :deep(.v-btn) {
    min-height: 2.5em !important;
    min-width: 5em !important;
  }

  .back-btn {
    top: -95px;
  }

  :deep(.unrestricted-input), :deep(.restricted-input-container) {
    height: 15%;
  }

  :deep(.v-text-field) {
    input[type="text"] {
      font-size: 1.5em;
    }
  }
}

.jungle-content-wrapper {
  background: #F8F4E0;
  border-radius: 5px;
  flex-direction: column;
  z-index: 95;
  margin: auto;
}

:deep(.jungle-content) {
  height: 100%;
  overflow: hidden;

  &.with-padding {
    padding: 2% 3%;
  }
}

.faded {
  * {
    transition: filter 0.3s;
  }
  .back-btn:not(.help-overlay__active-item), .page-title {
    filter: brightness(0.5);
  }
}

.is_mobile {
  .jungle-container {
    padding: 80px 0;
    overflow-x: hidden;
  }

  .back-btn {
    top: -68px;
  }

  .page-title {
    left: 6vw;
  }
}
</style>

<style lang="scss">
.jungle-transition-wrapper {
  overflow: hidden;
}
</style>
